const columns = [
  {
    label: 'TG username',
    field: 'telegram_username',
  },
  {
    label: 'TG id',
    field: 'telegram_id',
  },
  {
    label: 'User Id',
    field: 'user_id',
  },
  {
    label: 'Created Date',
    field: 'created_at',
  },
  {
    label: 'Updated Date',
    field: 'updated_at',
  },
  {
    label: 'Ban Reason',
    field: 'ban_reason',
  },
  {
    label: 'Data',
    field: 'data',
  },
  {
    label: 'Space Taper Result Id',
    field: 'space_taper_result_id',
  },
  {
    label: 'Gem History Id',
    field: 'gem_history_id',
  },
  {
    label: 'Is Unbanned',
    field: 'is_unbanned',
  },
  {
    label: 'Action',
    field: 'action',
  },
]

export default columns

