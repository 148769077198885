<template>
  <div>
    <AppTable :columns="columns" :is-loading="requestInProgress" :pagination="{
      limit: bannedUsers.limit,
      count: bannedUsers.count,
      page: bannedUsers.page,
    }" :rows="bannedUsers.value" @change-limit="changePageLimit" @change-page="changePage">
      <template #prependFilters>
        <b-row>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">Telegram ID</label>
              <b-form-input v-model="telegramID" placeholder="Search" type="text" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1" md="3" xl="3">
            <b-form-group>
              <label class="mr-1">Telegram Username</label>
              <b-form-input v-model="username" placeholder="Search" type="text" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1 mt-auto pb-1" md="1" xl="2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="requestInProgress" class="mt-auto"
              type="button" variant="primary" @click="search">
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow } = {}">
        <TableRow :column="column" :formatted-row="formattedRow" :row="row" />

        <span v-if="column.field === 'telegram_username'">
          <a :href="`https://t.me/${row.telegramUsername}`" target="_blank">
            {{ row.telegramUsername }}
          </a>
        </span>

        <span v-else-if="column.field === 'telegram_id'">
          {{ row.telegramId }}
        </span>
        <span v-else-if="column.field === 'telegram_id'">
          {{ row.telegramId }}
        </span>
        <span v-else-if="column.field === 'user_id'">
          {{ row.userId }}
        </span>
        <span v-else-if="column.field === 'updated_at'">
          {{ new Date(row.updatedAt).toLocaleDateString() }}
        </span>
        <span v-else-if="column.field === 'created_at'">
          {{ new Date(row.createdAt).toLocaleDateString() }}
        </span>
        <span v-else-if="column.field === 'ban_reason'">
          {{ row.reason }}
        </span>

        <span v-else-if="column.field === 'data'">
          <div class="data-cell" v-html="formatData(row.data)"></div>
        </span>
        <span v-else-if="column.field === 'space_taper_result_id'">
          {{ row.spaceTaperResultId }}
        </span>
        <span v-else-if="column.field === 'gem_history_id'">
          {{ row.gemHistoryId }}
        </span>
        <span v-else-if="column.field === 'is_unbanned'">
          {{ row.isUnbanned ? 'Yes' : 'No' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.field === 'action'" class="d-flex">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="row.isUnbanned ? 'danger' : 'success'"
            class="mt-auto text-white" type="button" @click="handleUnban(row)">
            <p class="width-100 mb-0">{{ row.isUnbanned ? 'Ban' : 'Unban' }}</p>
          </b-button>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
export default {
  components: {
    AppTable,
    TableRow,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, checkErrorsAlert],
  data() {
    return {
      columnsDefault: columns,
      telegramID: '',
      username: '',
      email: '',
      isModalOpened: false,
      currentUser: null,
      reasons: {
        bot: 'Bot',
        other: 'Other',
        multiaccount: 'Multi account',
        abuse: 'Abuse',
        aml: 'AML',
      },
    }
  },

  computed: {
    columns() {
      return this.columnsDefault
    },
    ...mapGetters({
      bannedUsers: 'users/holdBannedUsers',
    }),

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchHoldBannedUsers: 'users/fetchHoldBannedUsers',
      shadowUnbanUser: 'users/shadowUnbanUser',
      shadowRebanUser: 'users/shadowRebanUser',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchUsers(query)
    },

    search() {
      const query = this.createQueryConfig({
        telegramId: this.telegramID,
        telegramUsername: this.username,
        page: 1,
      })

      return this.fetchUsers(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.fetchUsers(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.fetchUsers(query)
    },

    createQueryConfig(config) {
      return {
        page: this.bannedUsers.page,
        limit: this.bannedUsers.limit,
        telegramID: this.telegramID || undefined,
        telegramUsername: this.username || undefined,

        ...config,
      }
    },

    fetchUsers(query) {
      return this.waitRequest(() => this.fetchHoldBannedUsers(query).catch(this.checkErrorsAlert))
    },

    handleUnban(user) {
      if (this.requestInProgress) return;
      if (user.isUnbanned) {
        // For banning the user
        this.$swal({
          title: 'Do you want to ban user?',
          text: 'Edit the comment if needed:',
          icon: 'warning',
          input: 'text',
          inputValue: 'unbanned',
          showCancelButton: true,
          confirmButtonText: `Yes, ban '${user?.telegramUsername}'!`,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (!result.isConfirmed) return;
          const comment = result.value || 'unbanned';
          this.reban(user, comment);
        });
      } else {
        // For unbanning the user, prompt to change the comment
        this.$swal({
          title: 'Do you want to unban user?',
          text: 'Edit the comment if needed:',
          icon: 'warning',
          input: 'text',
          inputValue: 'unbanned',
          showCancelButton: true,
          confirmButtonText: `Yes, unban '${user?.telegramUsername}'!`,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (!result.isConfirmed) return;
          // Use the provided comment, or default to "unbanned"
          const comment = result.value || 'unbanned';
          this.unban(user, comment);
        });
      }
    },

    unban(user, comment) {
      // Pass the comment parameter in the unban action payload
      this.waitRequest(() => this.shadowUnbanUser({ userId: user.userId, comment }))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was unbanned',
              icon: 'BellIcon',
            },
          });
          this.initState();
        })
        .catch(this.checkErrorsAlert);
    },

    reban(user, comment) {
      this.waitRequest(() =>
        this.shadowRebanUser({
          userId: user.userId,
          reason: 'diamond-after-turn-off',
          comment: comment,
          // isUnbanned: user.isUnbanned,
        })
      )
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was banned',
              icon: 'BellIcon',
            },
          });
          this.initState();
        })
        .catch(this.checkErrorsAlert);
    },
    formatData(data) {
      if (data && typeof data === 'object') {
        return Object.entries(data)
          .map(([key, value]) => `${this.capitalize(key)}: ${value}`)
          .join('<br />');
      }
      return data;
    },
    capitalize(word) {
      if (typeof word !== 'string') return word
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

.data-cell {
  max-height: 48px;
  overflow-y: auto;
  padding: 0px 4px;
}

.data-cell::-webkit-scrollbar {
  width: 8px;
}

.data-cell::-webkit-scrollbar-track {
  background: transparent;
}

.data-cell::-webkit-scrollbar-thumb {
  background-color: rgba(90, 96, 114, 0.5);
  border-radius: 4px;
}

.data-cell::-webkit-scrollbar-button {
  display: none;
}

.data-cell {
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 96, 114, 0.5) transparent;
}
</style>
